<template>
    <div class="h5top-header flex">
        <img src="../public/images/logo.png" alt="" class="logo" />
        <div class="top-left flex">
            <div class="left-name" @click="$router.push('/register')" v-if="!isLogin">{{ $t('sign.desc2') }}</div>
            <div class="flexcenter" @click="$router.push('/login')" v-if="!isLogin">{{ $t('sign.desc1') }}</div>
            <img src="../public/images/new/avatar.svg" alt="" @click="$router.push('/user')" v-else />

            <img src="../public/images/langicon.png" alt="" @click="dialogBackset = true" />
        </div>
        <van-popup v-model:show="dialogBackset" round>
            <div class="lang-info">
                <div class="lang-title">{{ $t('sign.desc36') }} <img src="../public/images/closemodal.png" alt=""
                        @click="dialogBackset = false" /></div>
                <div class="lang-item flex" v-for="item in langList" :key="item.id" @click="handleCommand(item.code)">
                    <div>{{ item.name }}</div>
                    <img :src="item.code == lang ? require('../public/images/new/checkimg.png') : require('../public/images/new/checkimg1.png')"
                        alt="" />
                </div>
            </div>
        </van-popup>
    </div>
    <!-- <div class="h5top-header flex" v-else>
        <div class="act-left flex">
            <div class="flexcenter">{{ $t('activity.desc1') }}</div>
            <p class="flex" @click="handlerInvite"><span class="flexcenter" v-html="$t('activity.desc3')"></span> <img
                    src="../public/images/new/activityicon.png" alt="" /></p>
        </div>
        <div class="activityicon" @click="handlerClose">
            <img src="../public/images/new/activityicon1.png" alt="">
        </div>
    </div> -->
</template>

<script>
export default {
    data() {
        return {
            dialogBackset: false,
            langList: [],
            lang: '',
            isLogin: false,
            userinfo: {},
            showActivity: false
        }
    },
    mounted() {
        if (localStorage.getItem('ismobile') == 1 && localStorage.getItem('showActivity') == 0) {
            this.showActivity = true;
        }
        this.lang = localStorage.getItem('language');
        localStorage.getItem('token') == null ? this.isLogin = false : this.isLogin = true;
        if (localStorage.getItem('lang') == null) {
        this.$post(this.URL.home.lang, {}).then(res => {
            if (res.code == 0) {
                this.langList = res.data;
            } else {
                this.$message.error(res.message)
            }
        })
    }else{
        this.langList = JSON.parse(localStorage.getItem('lang'))
    }
        if (this.isLogin) {
            this.userinfo = this.$store.state.userData;
        }
    },
    methods: {
        handlerInvite() {
            if (localStorage.getItem('token') == null) {
                this.$router.push('/login')
            } else {
                this.$router.push('/invite')
            }
        },
        handlerClose() {
            this.showActivity = false;
            localStorage.setItem('showActivity', 1)
        },
        handleCommand(val) {

            localStorage.setItem('language', val);
            this.dialogBackset = false;
            setTimeout(() => {
                location.reload();
            }, 600);
        },
        dealAddress(str) {
            if (str && str.length > 6) {
                return str.substring(0, 3) + '....' + str.substring(str.length - 3, str.length)
            } else {
                return str
            }
        },
    }
}
</script>

<style lang="less" scoped>
.h5top-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    background: #000;
    z-index: 56;
    justify-content: space-between;

    .act-left {
        flex: 1;
        background: #C9FA5B;

        div {
            flex: 1;
            padding: 0 6px 0 12px;
            height: 48px;
            font-size: 14px;
            color: #000000;
            line-height: 16px;
            background: linear-gradient(90deg, #7BFAAB 0%, #4D48F5 73.36%, #9E44F6 100%);
        }

        p {
            flex: 0 0 88px;
            font-size: 14px;
            color: #000000;
            justify-content: center;

            span {
                line-height: 14px;
            }

            img {
                width: 8px;
                height: 14px;
                margin: 17px 0 0 8px;
            }
        }
    }

    .activityicon {
        flex: 0 0 48px;
        width: 48px;

        img {
            width: 48px;
            height: 48px;
        }
    }

    .logo {
        height: 22px;
        margin: 11px 0 0 16px;
    }

    .top-left {
        flex: 0 0 200px;
        margin-right: 16px;
        justify-content: flex-end;

        .left-name {
            font-size: 12px;
            color: #fff;
            line-height: 31px;
            margin: 7px 8px 0 0;
        }

        .flexcenter {
            flex: 0 0 94px;
            height: 31px;
            font-size: 12px;
            color: #D0D0D0;
            margin-top: 7px;
            font-family: 'Nebula';
            background: url('../public/images/new/btnbg22.png') center no-repeat;
            background-size: 100% 100%;
        }

        img {
            flex: 0 0 24px;
            width: 24px;
            height: 24px;
            margin: 10px 0 0 12px;
        }
    }
}

.lang-info {
    width: calc(100vw - 8px);
    padding: 32px 16px;

    .lang-title {
        position: relative;
        font-size: 18px;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;

        img {
            position: absolute;
            top: 4px;
            right: 0;
            width: 14px;
            height: 14px;
            cursor: pointer;
        }
    }

    .lang-item {
        padding: 10px 0;

        &:last-child {
            margin-bottom: 0;
        }

        div {
            flex: 1;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 44px;
        }

        img {
            width: 16px;
            height: 16px;
            margin: 14px 0 0 0;

        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}</style>