<template>
  <div class="footer-out">
    <div class="footer flex">
      <div
        class="footer-item"
        v-for="(item, index) in list"
        :key="item.path"
        @click="handlerPath(item.path)"
      >
        <img :src="act == index ? item.seleicon : item.icon" alt="" />
        <p
          :style="{
            color: act == index ? '#C9FA5B' : '#999999',
          }"
        >
          {{ item.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["act"],
  data() {
    return {
      list: [
        {
          name: this.$t('home.desc'),
          icon: require("../public/images/new/footericon.png"),
          seleicon: require("../public/images/new/footericon1.png"),
          path: "/home",
        },
        {
          name: this.$t('stake.desc95'),//this.$t("footer.desc22"),
          icon: require("../public/images/new/footericon5.png"),
          seleicon: require("../public/images/new/footericon6.png"),
          path: "/stake",//"/node",
        },
        {
          name: this.$t("market.desc120"),
          icon: require("../public/images/new/footericon11.png"),
          seleicon: require("../public/images/new/footericon12.png"),
          path: "/market",
        },
        {
            name: this.$t('home.desc3'),
            icon: require('../public/images/new/footericon13.png'),
            seleicon: require('../public/images/new/footericon14.png'),
            path: '/quotes'
        },
        {
          name: this.$t("footer.desc23"),
          icon: require("../public/images/new/footericon9.png"),
          seleicon: require("../public/images/new/footericon10.png"),
          path: "/asset",
        },

        // {
        //   name: this.$t("footer.desc25"),
        //   icon: require("../public/images/new/footericon3.png"),
        //   seleicon: require("../public/images/new/footericon4.png"),
        //   path: "/user",
        // },
      ],
    };
  },
  methods: {
    handlerPath(url) {
      if (localStorage.getItem("token") == null) {
        if (url == "/home" || url == "/node") {
          this.$router.push(url);
          return;
        }
        this.$router.push("/login");
        return;
      }

      this.$router.push(url);
    },
    handlerClose() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 0 11px;
  background: url("../public/images/new/footerbg.png") top no-repeat;
  background-size: 100% 100%;

  z-index: 56;
  display: flex;

  &-out {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #000;
    z-index: 56;
  }

  .line {
    position: absolute;
    top: -11px;
    left: 0;
    width: 100%;
  }

  .footer-item {
    flex: 0 0 20%;
    font-size: 10px;
    color: #999999;
    text-align: center;
    padding-top: 11px;

    img {
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
    }
  }
}
</style>
